import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import ArqProServerAPI from "./ArqProServerAPI";
import { Flex, Alert, Box, FormControl, FormLabel, Input, VStack, Stack, Button, Heading, Text, useColorModeValue, useToast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { ReactSession } from "react-client-session";
import SelectProSubscription from "./SelectProSubscription";

const Login = (props) => {
    const [showPasswordField, setShowPasswordField] = useState(false);
    const [loggingIn, setLoggingIn] = useState(false);
    const [emailVerificationRequired, setEmailVerificationRequired] = useState(false);
    const [twoFactorRequired, setTwoFactorRequired] = useState(false);
    const [proSubscriptions, setProSubscriptions] = useState();
    const [selectedProSubscriptionId, setSelectedProSubscriptionId] = useState();

    const toast = useToast();

    const onSubmit = async (data) => {
        const { emailAddress, password, verificationCode, twoFactorToken } = data;

        try {
            ReactSession.set("loggedInAccount", undefined);
            ReactSession.set("loggedInProSubscription", undefined);
            setLoggingIn(true);
            const response = await ArqProServerAPI.logIn({
                emailAddress,
                password,
                verificationCode,
                twoFactorToken
            });
            ReactSession.set("loggedInAccount", response.data.account.data);

            if (response.data.proSubscriptions.length === 0) {
                toast({ title: "Error", description: "No Arq Pro subscription found for account.", status: "error", duration: 5000, isClosable: true });
                return;
            }
            setProSubscriptions(response.data.proSubscriptions);
        } catch (err) {
            const response = err.response;
            let message = err.message;
            if (response && response.data && response.data.error) {
                message = response.data.error;
            }
            if (response && response.data && response.data.errorCode === "EmailVerificationRequired") {
                setEmailVerificationRequired(true);
            } else if (response && response.data && response.data.errorCode === "AccountNotFound" && !showPasswordField) {
                setShowPasswordField(true);
            } else if (response && response.data && response.data.errorCode === "IncorrectEmailVerificationCode") {
                setEmailVerificationRequired(true);
                toast({ title: "Error", description: message, status: "error", duration: 5000, isClosable: true });
            } else if (response && response.data && response.data.errorCode === "PasswordRequired") {
                setShowPasswordField(true);
            } else if (response && response.data && response.data.errorCode === "InvalidTwoFactorToken") {
                if (!twoFactorRequired) {
                    setTwoFactorRequired(true);
                    setShowPasswordField(true);
                } else {
                    toast({ title: "Error", description: message, status: "error", duration: 5000, isClosable: true });
                }
            } else {
                toast({ title: "Error", description: message, status: "error", duration: 5000, isClosable: true });
            }
        } finally {
            setLoggingIn(false);
        }
    };

    const handleProSubscriptionSelected = (proSubscriptionId) => {
        setSelectedProSubscriptionId(proSubscriptionId);
    };
    const handleProSubscriptionSelectCancel = () => {
        setProSubscriptions(undefined);
    };

    const bgColor = useColorModeValue("gray.50", "gray.800");
    const boxBGColor = useColorModeValue("white", "gray.700");

    const {
        register,
        handleSubmit,
        setFocus,
        formState: { errors }
    } = useForm();

    useEffect(() => {
        if (showPasswordField) {
            // Run it within a setTimeout() or else it doesn't work:
            setTimeout(() => {
                setFocus("password");
            });
        }
    }, [showPasswordField, setFocus]);

    if (selectedProSubscriptionId) {
        return <Redirect to="/dashboard" />;
    }
    if (proSubscriptions) {
        return <SelectProSubscription proSubscriptions={proSubscriptions} onOK={handleProSubscriptionSelected} onCancel={handleProSubscriptionSelectCancel} />;
    }
    return (
        <Flex minH={"100vh"} align={"center"} justify={"center"} bg={bgColor}>
            <VStack spacing={8} mx={"auto"} w={"xl"} py={12} px={6}>
                <Stack align={"center"}>
                    <Heading fontSize={"4xl"}>Log into Arq Pro</Heading>
                </Stack>
                <Box w="100%" rounded={"lg"} bg={boxBGColor} boxShadow={"lg"} p={8}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Stack spacing={4}>
                            <FormControl id="email">
                                <FormLabel>Email address</FormLabel>
                                <Input disabled={loggingIn} type="email" {...register("emailAddress", { required: "Please enter your email address" })} />
                                {errors.emailAddress && <Alert status="error">{errors.emailAddress.message}</Alert>}
                            </FormControl>
                            {emailVerificationRequired && (
                                <>
                                    <Flex>Please check for an email from us with a verification code.</Flex>
                                    <FormLabel>Verification code</FormLabel>
                                    <Input disabled={loggingIn} {...register("verificationCode", { required: "Please enter the verification code we sent you" })} />
                                    {errors.verificationCode && <Alert status="error">{errors.verificationCode.message}</Alert>}
                                </>
                            )}
                            {(emailVerificationRequired || showPasswordField) && (
                                <FormControl id="password">
                                    <FormLabel>{emailVerificationRequired ? "Choose a password" : "Password"}</FormLabel>
                                    <Input disabled={loggingIn} type="password" {...register("password", { required: "Please enter your password" })} />
                                    {errors.password && <Alert status="error">{errors.password.message}</Alert>}
                                </FormControl>
                            )}
                            {twoFactorRequired && (
                                <FormControl id="twoFactorToken">
                                    <FormLabel>2FA token or recovery code</FormLabel>
                                    <Input disabled={loggingIn} placeholder="6-character code" {...register("twoFactorToken", { required: "Please enter a two-factor token" })} />
                                    {errors.twoFactorToken && <Alert status="error">{errors.twoFactorToken.message}</Alert>}
                                </FormControl>
                            )}
                            <Button
                                isLoading={loggingIn}
                                type="submit"
                                bg={"blue.400"}
                                color={"white"}
                                _hover={{
                                    bg: "blue.500"
                                }}
                            >
                                {showPasswordField ? "Log In" : "Continue"}
                            </Button>
                        </Stack>
                    </form>
                </Box>
                <Stack align={"center"}>
                    <Text mt={5}>Want to start a free trial of Arq Pro?</Text>
                    <Link to="/register">
                        <Button
                            type="submit"
                            bg={"green.400"}
                            color={"white"}
                            _hover={{
                                bg: "green.500"
                            }}
                        >
                            Create Arq Pro Trial Subscription
                        </Button>
                    </Link>
                </Stack>
            </VStack>
        </Flex>
    );
};

export default Login;
