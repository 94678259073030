const axios = require("axios");

const ArqProServerAPI = function () {};

const defaultHeaders = {
    "x-arq-shared-secret": "668888889b7b4a968518b73e590493c0a064d63b3ad1f205e64f0abdc9124a58"
};
ArqProServerAPI.defaultHeaders = defaultHeaders;

ArqProServerAPI.url = function () {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
        return "http://localhost:5100";
    }
    return "https://pro.arqbackup.com";
};

ArqProServerAPI.isLoggedIn = async function () {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/isLoggedIn";
        const response = await axios.get(url, {
            headers: { ...defaultHeaders },

            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.logIn = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/logIn";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },

            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.acceptLicense = async function () {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/accounts/acceptLicense";
        const response = await axios.post(
            url,
            {},
            {
                headers: { ...defaultHeaders },
                withCredentials: true
            }
        );
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.setArqProSubscriptionId = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/setProSubscriptionId";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },

            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.logOut = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/logOut";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.register = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/register";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.deleteSubscription = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/deleteSubscription";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.sendVerificationCodeEmail = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/sendVerificationCodeEmail";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.verifyEmailAddress = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/verifyEmailAddress";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.subscriptionAndPaymentInfo = async function () {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/subscriptionAndPaymentInfo";
        const response = await axios.get(url, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};

ArqProServerAPI.dashboardInfo = async function () {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/dashboard/allInfo";
        const response = await axios.get(url, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.bytesStoredOverTimeByGroup = async function () {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/dashboard/bytesStoredOverTimeByGroup";
        const response = await axios.get(url, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.computers = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/computers";
        const response = await axios.get(url, {
            params,
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.computer = async function (computerId) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/computer/" + computerId;
        const response = await axios.get(url, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.computerForLocalStorageLocationId = async function (localStorageLocationId) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/computerForLocalStorageLocationId/" + localStorageLocationId;
        const response = await axios.get(url, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.setComputerSettings = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/computers/settings";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.deleteComputer = async function (computerId) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/computers/deleteComputer";
        const response = await axios.post(
            url,
            { computerId },
            {
                headers: { ...defaultHeaders },
                withCredentials: true
            }
        );
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.proComputerCommands = async function (proComputerId) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/computerCommands/byComputer/" + proComputerId;
        const response = await axios.get(url, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.proLicenses = async function () {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/proLicenses";
        const response = await axios.get(url, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.accounts = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/accounts";
        const response = await axios.get(url, {
            params,
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.unfinishedInvitations = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/accounts/unfinishedInvitations";
        const response = await axios.get(url, {
            params,
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.cancelInvitation = async function (invitationId) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/accounts/cancelInvitation";
        const response = await axios.post(
            url,
            { invitationId },
            {
                headers: { ...defaultHeaders },
                withCredentials: true
            }
        );
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.finishInvitation = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/accounts/finishInvitation";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.account = async function (accountId) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/accounts/" + accountId;
        const response = await axios.get(url, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.inviteAdministrator = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/accounts/inviteAdministrator";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.updateAccount = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/accounts/update";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.resetAccountPassword = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/accounts/resetPassword";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};

ArqProServerAPI.deleteAccount = async function (accountId) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/accounts/delete";
        const response = await axios.post(
            url,
            { accountId },
            {
                headers: { ...defaultHeaders },
                withCredentials: true
            }
        );
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.setLoggedInAccountProps = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/accounts/setLoggedInAccountProps";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.enable2FA = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/accounts/me/enable2FA";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.disable2FA = async function () {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/accounts/me/disable2FA";
        const response = await axios.post(
            url,
            {},
            {
                headers: { ...defaultHeaders },
                withCredentials: true
            }
        );
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.regenerate2FACodes = async function () {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/accounts/me/regenerate2FACodes";
        const response = await axios.post(
            url,
            {},
            {
                headers: { ...defaultHeaders },
                withCredentials: true
            }
        );
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};

ArqProServerAPI.groups = async function () {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/groups";
        const response = await axios.get(url, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.group = async function (groupId) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/group/" + groupId;
        const response = await axios.get(url, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.createGroup = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/groups/add";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.updateGroup = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/groups/update";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.groupInUse = async function (groupId) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/groups/inUse/" + groupId;
        const response = await axios.get(url, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response.data.groupInUse;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.deleteGroup = async function (groupId) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/groups/delete";
        await axios.post(
            url,
            { groupId },
            {
                headers: { ...defaultHeaders },
                withCredentials: true
            }
        );
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.groupsWithComputers = async function () {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/groupsWithComputers";
        const response = await axios.get(url, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.deleteComputerGroup = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/computers/deleteComputerGroup";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.saveComputerGroup = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/computers/saveComputerGroup";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.alerts = async function () {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/alerts";
        const response = await axios.get(url, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.dismissAlert = async function (alertId) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/alerts/dismiss";
        const response = await axios.post(
            url,
            { alertId },
            {
                headers: { ...defaultHeaders },
                withCredentials: true
            }
        );
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.activities = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/activities";
        const response = await axios.get(url, {
            params,
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.activityLogPageCount = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/activities/activityLog/pageCount";
        const response = await axios.get(url, {
            params,
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.activityLogPage = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/activities/activityLog/page";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};

ArqProServerAPI.computersWithBackupPlanCounts = async function () {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/computersWithBackupPlanCounts";
        const response = await axios.get(url, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.computersForGroup = async function (groupId) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/computersForGroup";
        const response = await axios.get(url, {
            headers: { ...defaultHeaders },
            withCredentials: true,
            params: { groupId }
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.storageLocations = async function () {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/storageLocations/all";
        const response = await axios.get(url, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.storageLocation = async function (storageLocationId) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/storageLocation/" + storageLocationId;
        const response = await axios.get(url, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.updateStorageLocationName = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/storageLocations/updateName";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.updateStorageLocationKeyPair = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/storageLocations/updateKeyPair";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
// 9/9/22: commented out this method because we're not currently using it
// ArqProServerAPI.updateStorageLocationProps = async function (params) {
//     try {
//         const url = ArqProServerAPI.url() + "/arqpro/v1/storageLocations/updateProps";
//         const response = await axios.post(url, params, {
//             headers: { ...defaultHeaders },
//             withCredentials: true
//         });
//         return response;
//     } catch (err) {
//         console.log(err);
//         throw err;
//     }
// };

ArqProServerAPI.getS3Buckets = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/aws/getS3Buckets";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.getB2Buckets = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/b2/getB2Buckets";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.addStorageLocation = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/storageLocations/addStorageLocation";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.deleteStorageLocation = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/storageLocations/deleteStorageLocation";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.objectLockAvailableForStorageLocation = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/storageLocations/objectLockAvailable";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        const { objectLockAvailable } = response.data;
        return objectLockAvailable;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.backupPlans = async function () {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/backupPlans/all";
        const response = await axios.get(url, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.backupPlansWithUUIDAndLatestActivityForComputer = async function (computerId) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/backupPlans/forComputerWithUUIDAndLatestActivity/" + computerId;
        const response = await axios.get(url, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.localBackupPlansForProComputer = async function (computerId) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/localBackupPlans/forProComputer/" + computerId;
        const response = await axios.get(url, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.localBackupPlan = async function (backupPlanId) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/localBackupPlans/" + backupPlanId;
        const response = await axios.get(url, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.updateLocalBackupPlan = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/localBackupPlans/updateLocalBackupPlan";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};

ArqProServerAPI.localStorageLocationsForProComputer = async function (computerId) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/localStorageLocations/forProComputer/" + computerId;
        const response = await axios.get(url, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.localStorageLocation = async function (slId) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/localStorageLocation/" + slId;
        const response = await axios.get(url, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.updateLocalStorageLocationName = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/localstorageLocations/updateName";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.updateLocalStorageLocationKeyPair = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/localstorageLocations/updateKeyPair";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};

ArqProServerAPI.backupPlan = async function (backupPlanId) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/backupPlans/" + backupPlanId;
        const response = await axios.get(url, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.backupPlanComputers = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/backupPlans/getBackupPlanComputers";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.computerNamesByBackupPlanId = async function () {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/backupPlanComputers/getComputerNamesByBackupPlanId";
        const response = await axios.get(url, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};

ArqProServerAPI.addBackupPlan = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/backupplans/addBackupPlan";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.addLocalBackupPlan = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/localbackupplans/addLocalBackupPlan";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.updateBackupPlan = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/backupplans/updateBackupPlan";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.setBackupPlanEncryptionPassword = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/backupplans/setBackupPlanEncryptionPassword";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.setLocalBackupPlanEncryptionPassword = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/localbackupplans/setLocalBackupPlanEncryptionPassword";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};

ArqProServerAPI.sendTestEmail = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/backupplans/sendTestEmail";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};

ArqProServerAPI.deleteBackupPlan = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/backupPlans/deleteBackupPlan";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.deleteLocalBackupPlan = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/localbackupplans/deleteLocalBackupPlan";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.backUpNow = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/backupPlans/backUpNow";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.stopBackup = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/backupPlans/stop";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.backupSetUUIDs = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/storageBrowser/backupSetUUIDs";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.backupSet = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/storageBrowser/backupSet";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.backupFolders = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/storageBrowser/backupFolders";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.backupRecords = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/storageBrowser/backupRecords";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.tree = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/storageBrowser/tree";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.startRestoreJob = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/startRestoreJob";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.addLocalStorageLocation = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/addLocalStorageLocation";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.deleteLocalStorageLocation = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/localStorageLocations/delete";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.processGoogleOAuthCode = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/oauth/processGoogleOAuthCode";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.getGCSBuckets = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/gcs/getGCSBuckets";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.tellComputerToSendAgentLog = async function (proComputerId) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/computers/tellComputerToSendAgentLog";
        const response = await axios.post(
            url,
            { proComputerId },
            {
                headers: { ...defaultHeaders },
                withCredentials: true
            }
        );
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.createPaymentIntent = async function () {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/subscription/createPaymentIntent";
        const response = await axios.post(
            url,
            {},
            {
                headers: { ...defaultHeaders },
                withCredentials: true
            }
        );
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.removePaymentMethod = async function () {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/subscription/removePaymentMethod";
        const response = await axios.post(
            url,
            {},
            {
                headers: { ...defaultHeaders },
                withCredentials: true
            }
        );
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.updateSubscription = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/subscription/update";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.impersonations = async function () {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/impersonation/impersonations";
        const response = await axios.get(url, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
ArqProServerAPI.impersonate = async function (params) {
    try {
        const url = ArqProServerAPI.url() + "/arqpro/v1/impersonation/impersonate";
        const response = await axios.post(url, params, {
            headers: { ...defaultHeaders },
            withCredentials: true
        });
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};

export default ArqProServerAPI;
