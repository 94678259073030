import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { ChakraProvider, theme } from "@chakra-ui/react";
import Layout from "./Layout";
import Login from "./Login";
import AcceptLicense from "./AcceptLicense";
import Register from "./Register";
import VerifyEmailAddress from "./VerifyEmailAddress";
import Logout from "./Logout";
import { ReactSession } from "react-client-session";

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
);

function App() {
    ReactSession.setStoreType("sessionStorage");

    return (
        <ChakraProvider theme={theme}>
            <HashRouter>
                <React.Suspense fallback={loading}>
                    <Switch>
                        <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
                        <Route exact path="/acceptLicense" name="Accept License" render={(props) => <AcceptLicense {...props} />} />
                        <Route exact path="/register" name="Login Page" render={(props) => <Register {...props} />} />
                        <Route exact path="/verifyEmailAddress" name="Verify Email Address" render={(props) => <VerifyEmailAddress {...props} />} />
                        <Route exact path="/logout" name="Logout Page" render={(props) => <Logout {...props} />} />

                        <Route
                            path="/"
                            name="Home"
                            render={(props) => {
                                return <Layout {...props} />;
                            }}
                        />
                    </Switch>
                </React.Suspense>
            </HashRouter>
        </ChakraProvider>
    );
}

export default App;
