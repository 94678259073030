import React, { useEffect, useState } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import ArqProServerAPI from "./ArqProServerAPI";
import { Grid, GridItem, Flex, Alert, Box, Input, VStack, Stack, Button, Heading, useColorModeValue, useToast, Text } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { ReactSession } from "react-client-session";

const Register = (props) => {
    const [status, setStatus] = useState("new");
    const [enteredEmailAddress, setEnteredEmailAddress] = useState();
    const [loggingIn, setLoggingIn] = useState(false);
    let history = useHistory();
    let location = useLocation();

    let { from } = location.state || { from: { pathname: "/" } };
    const toast = useToast();

    const {
        register,
        watch,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm();

    const onSubmit = async (data) => {
        const { companyName, fullName, phone, emailAddress, password, emailVerificationCode } = data;

        try {
            setLoggingIn(true);
            const response = await ArqProServerAPI.register({
                companyName,
                fullName,
                phone,
                emailAddress,
                password,
                emailVerificationCode
            });
            const { account, proSubscription } = response.data;
            ReactSession.set("loggedInAccount", account.data);
            ReactSession.set("loggedInProSubscription", proSubscription.data);
            history.replace(from);
        } catch (err) {
            const response = err.response;
            let message = err.message;
            let errorCode;

            if (response && response.data && response.data.error) {
                message = response.data.error;
                errorCode = response.data.errorCode;
            }
            if (errorCode === "PasswordRequired") {
                setEnteredEmailAddress(emailAddress);
                setValue("emailVerificationCode", undefined);
                setValue("password", undefined);
                setValue("password2", undefined);
                setStatus(errorCode);
            } else if (errorCode === "IncorrectPassword") {
                setEnteredEmailAddress(emailAddress);
                setValue("emailVerificationCode", undefined);
                setValue("password", undefined);
                setValue("password2", undefined);
                setStatus(errorCode);
                toast({ title: "Error", description: message, status: "error", duration: 5000, isClosable: true });
            } else if (errorCode === "EmailVerificationRequired") {
                toast({ description: message, status: "info", duration: 5000, isClosable: true });
                setEnteredEmailAddress(emailAddress);
                setValue("emailVerificationCode", undefined);
                setValue("password", undefined);
                setValue("password2", undefined);
                setStatus(errorCode);
            } else {
                setStatus("new");
                setEnteredEmailAddress(undefined);
                toast({ title: "Error", description: message, status: "error", duration: 5000, isClosable: true });
            }
        } finally {
            setLoggingIn(false);
        }
    };

    useEffect(() => {
        if (process.env.NODE_ENV === "development") {
            setValue("companyName", "Haystack");
            setValue("fullName", "Stefan Reitshamer");
            setValue("phone", "617-306-8743");
            setValue("emailAddress", "stefan@arqbackup.com");
        }
    }, [setValue]);

    return (
        <Flex minH={"100vh"} align={"center"} justify={"center"} bg={useColorModeValue("gray.50", "gray.800")}>
            <VStack spacing={8} mx={"auto"} w={"3xl"} py={12} px={6}>
                <Stack align={"center"}>
                    <Heading fontSize={"4xl"}>Start Arq Pro Free Trial</Heading>
                    <Box>Arq Pro is managed backup</Box>
                    <Box>
                        <Text as="mark">Arq Pro is only available to US customers</Text>
                    </Box>
                </Stack>
                <Box w="100%" rounded={"lg"} bg={useColorModeValue("white", "gray.700")} boxShadow={"lg"} p={8}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid templateColumns="max-content 1fr" gap={6}>
                            <GridItem textAlign="right" mt={2}>
                                Account owner email address:
                            </GridItem>
                            <GridItem>
                                {enteredEmailAddress ? (
                                    <Flex mt={2}>{enteredEmailAddress}</Flex>
                                ) : (
                                    <>
                                        <Input disabled={loggingIn} type="email" {...register("emailAddress", { required: "Please enter an email address" })} />
                                        {errors.emailAddress && <Alert status="error">{errors.emailAddress.message}</Alert>}
                                    </>
                                )}
                            </GridItem>
                            <GridItem textAlign="right" mt={2}>
                                Account owner full name:
                            </GridItem>
                            <GridItem>
                                <Input disabled={loggingIn} {...register("fullName", { required: "Please enter an account owner name" })} />
                                {errors.fullName && <Alert status="error">{errors.fullName.message}</Alert>}
                            </GridItem>
                            <GridItem textAlign="right" mt={2}>
                                Phone number:
                            </GridItem>
                            <GridItem>
                                <Input disabled={loggingIn} {...register("phone", { required: "Please enter a phone number" })} />
                                {errors.phone && <Alert status="error">{errors.phone.message}</Alert>}
                            </GridItem>
                            <GridItem textAlign="right" mt={2}>
                                Company name:
                            </GridItem>
                            <GridItem>
                                <Input disabled={loggingIn} {...register("companyName", { required: "Please enter a company name" })} />
                                {errors.companyName && <Alert status="error">{errors.companyName.message}</Alert>}
                            </GridItem>
                            {status === "EmailVerificationRequired" && (
                                <>
                                    <GridItem textAlign="right" mt={2}>
                                        Email verification code:
                                    </GridItem>
                                    <GridItem>
                                        <Input
                                            disabled={loggingIn}
                                            placeholder="Please enter the verification code we sent you"
                                            {...register("emailVerificationCode", { required: "Please enter the verification code we sent you" })}
                                        />
                                        {errors.phone && <Alert status="error">{errors.phone.message}</Alert>}
                                    </GridItem>
                                    <GridItem textAlign="right" mt={2}>
                                        Choose a password:
                                    </GridItem>
                                    <GridItem>
                                        <Input disabled={loggingIn} type="password" {...register("password", { required: "Please enter a password" })} />
                                        {errors.password && <Alert status="error">{errors.password.message}</Alert>}
                                    </GridItem>
                                    <GridItem textAlign="right" mt={2}>
                                        Repeat password:
                                    </GridItem>
                                    <GridItem>
                                        <Input
                                            disabled={loggingIn}
                                            type="password"
                                            {...register("password2", {
                                                validate: (value) => value === watch("password") || "Passwords don't match."
                                            })}
                                        />
                                        {errors.password2 && <Alert status="error">{errors.password2.message}</Alert>}
                                    </GridItem>
                                </>
                            )}
                            {(status === "PasswordRequired" || status === "IncorrectPassword") && (
                                <>
                                    <GridItem textAlign="right" mt={2}>
                                        Password:
                                    </GridItem>
                                    <GridItem>
                                        <Input disabled={loggingIn} type="password" {...register("password", { required: "Please enter a password" })} />
                                        {errors.password && <Alert status="error">{errors.password.message}</Alert>}
                                    </GridItem>
                                </>
                            )}
                        </Grid>

                        <Stack spacing={4} mt={6}>
                            <Button
                                isLoading={loggingIn}
                                type="submit"
                                bg={"blue.400"}
                                color={"white"}
                                _hover={{
                                    bg: "blue.500"
                                }}
                            >
                                {status === "new" ? "Continue" : "Create Arq Pro Trial Subscription"}
                            </Button>
                        </Stack>
                    </form>
                </Box>
                <Stack align={"center"}>
                    <Link to="/login">
                        <Button
                            disabled={loggingIn}
                            type="submit"
                            bg={"green.400"}
                            color={"white"}
                            _hover={{
                                bg: "green.500"
                            }}
                        >
                            Log Into Existing Account
                        </Button>
                    </Link>
                </Stack>
            </VStack>
        </Flex>
    );
};

export default Register;
