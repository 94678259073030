import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ArqProServerAPI from "./ArqProServerAPI";
import { Link, Flex, Alert, Box, FormLabel, Input, VStack, Stack, Button, Heading, useColorModeValue, useToast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";

const VerifyEmailAddress = (props) => {
    const [loggingIn, setLoggingIn] = useState(false);
    let history = useHistory();
    let location = useLocation();

    let { from } = location.state || { from: { pathname: "/" } };
    const toast = useToast();
    const linkColor = useColorModeValue("blue.600", "blue.100");

    const onSubmit = async (data) => {
        const { emailVerificationCode } = data;

        try {
            setLoggingIn(true);
            await ArqProServerAPI.verifyEmailAddress({ emailVerificationCode });
            history.replace(from);
        } catch (err) {
            if (err.response?.status === 401) {
                history.replace("/login");
                return;
            }
            const response = err.response;
            let message = err.message;
            if (response && response.data && response.data.error) {
                message = response.data.error;
            }
            toast({ title: "Error", description: message, status: "error", duration: 5000, isClosable: true });
        } finally {
            setLoggingIn(false);
        }
    };

    const handleResendCode = async () => {
        try {
            setLoggingIn(true);
            await ArqProServerAPI.sendVerificationCodeEmail();
            toast({ title: "Verification code sent", description: "Please check your inbox.", status: "success", duration: 5000, isClosable: true });
        } catch (err) {
            console.log(err);
            if (err.response?.status === 401) {
                history.replace("/login");
                return;
            }
            const response = err.response;
            let message = err.message;
            if (response && response.data && response.data.error) {
                message = response.data.error;
            }
            toast({ title: "Error", description: message, status: "error", duration: 5000, isClosable: true });
        } finally {
            setLoggingIn(false);
        }
    };

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    return (
        <Flex minH={"100vh"} align={"center"} justify={"center"} bg={useColorModeValue("gray.50", "gray.800")}>
            <VStack spacing={8} mx={"auto"} w={"xl"} py={12} px={6}>
                <Stack align={"center"}>
                    <Heading fontSize={"4xl"}>Verify Arq Pro Email Address</Heading>
                </Stack>
                <Box w="100%" rounded={"lg"} bg={useColorModeValue("white", "gray.700")} boxShadow={"lg"} p={8}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Stack spacing={4}>
                            <FormLabel>Please enter the verification code we sent you via email:</FormLabel>
                            <Flex>
                                <Input
                                    w={"50%"}
                                    disabled={loggingIn}
                                    placeholder="6-digit code"
                                    {...register("emailVerificationCode", { required: "Please enter your verification code" })}
                                />
                                <Button
                                    w={"50%"}
                                    ml={2}
                                    isLoading={loggingIn}
                                    type="submit"
                                    bg={"blue.400"}
                                    color={"white"}
                                    _hover={{
                                        bg: "blue.500"
                                    }}
                                >
                                    Verify
                                </Button>
                            </Flex>
                            {errors.emailVerificationCode && <Alert status="error">{errors.emailVerificationCode.message}</Alert>}
                            <Link style={{ textDecoration: "underline" }} color={linkColor} onClick={handleResendCode}>
                                Re-send verification code
                            </Link>
                        </Stack>
                    </form>
                </Box>
            </VStack>
        </Flex>
    );
};

export default VerifyEmailAddress;
