import React, { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ReactSession } from "react-client-session";

const IsLoggedIn = React.lazy(() => import("./IsLoggedIn"));

const Dashboard = React.lazy(() => import("./dashboard/Dashboard"));
const Alerts = React.lazy(() => import("./alerts/Alerts"));
const AddGroup = React.lazy(() => import("./groups/AddGroup"));
const EditGroup = React.lazy(() => import("./groups/EditGroup"));
const Groups = React.lazy(() => import("./groups/Groups"));
const Accounts = React.lazy(() => import("./accounts/Accounts"));
const ShowAccount = React.lazy(() => import("./accounts/ShowAccount"));
const AddAccount = React.lazy(() => import("./accounts/AddAccount"));
const EditAccount = React.lazy(() => import("./accounts/EditAccount"));
const Computers = React.lazy(() => import("./computers/Computers"));
const ShowComputer = React.lazy(() => import("./computers/ShowComputer"));
const EditComputer = React.lazy(() => import("./computers/EditComputer"));
const SetAppPassword = React.lazy(() => import("./computers/SetAppPassword"));
const StorageLocations = React.lazy(() => import("./storagelocations/StorageLocations"));
const ShowStorageLocation = React.lazy(() => import("./storagelocations/ShowStorageLocation"));
const BrowseStorageLocation = React.lazy(() => import("./storagelocations/browse/BrowseStorageLocation"));
const EditStorageLocationName = React.lazy(() => import("./storagelocations/EditStorageLocationName"));
const EditSFTPStorageLocation = React.lazy(() => import("./storagelocations/EditSFTPStorageLocation"));
const ReplaceStorageLocationKeyPair = React.lazy(() => import("./storagelocations/ReplaceStorageLocationKeyPair"));
const AddStorageLocation = React.lazy(() => import("./storagelocations/add/AddStorageLocation"));
const BackupPlans = React.lazy(() => import("./backupplans/BackupPlans"));
const AddBackupPlan = React.lazy(() => import("./backupplans/add/AddBackupPlan"));
const EditBackupPlan = React.lazy(() => import("./backupplans/edit/EditBackupPlan"));
const Activities = React.lazy(() => import("./activities/Activities"));
const ActivityLog = React.lazy(() => import("./activities/ActivityLog"));
const Subscription = React.lazy(() => import("./subscription/Subscription"));
const EditSubscriptionName = React.lazy(() => import("./subscription/EditSubscriptionName"));
const EnterPaymentMethod = React.lazy(() => import("./subscription/EnterPaymentMethod"));
const DesktopMain = React.lazy(() => import("./DesktopMain"));
const EditLocalBackupPlan = React.lazy(() => import("./backupplans/edit/EditLocalBackupPlan"));
const AddLocalBackupPlan = React.lazy(() => import("./localbackupplans/AddLocalBackupPlan"));
const AddLocalStorageLocation = React.lazy(() => import("./localstoragelocations/AddLocalStorageLocation"));
const AddOAuthLocalStorageLocation = React.lazy(() => import("./localstoragelocations/AddOAuthLocalStorageLocation"));
const AddS3LocalStorageLocation = React.lazy(() => import("./localstoragelocations/AddS3LocalStorageLocation"));
const AddS3CompatibleLocalStorageLocation = React.lazy(() => import("./localstoragelocations/AddS3CompatibleLocalStorageLocation"));
const AddB2LocalStorageLocation = React.lazy(() => import("./localstoragelocations/AddB2LocalStorageLocation"));
const AddSFTPLocalStorageLocation = React.lazy(() => import("./localstoragelocations/AddSFTPLocalStorageLocation"));
const AddGCSLocalStorageLocation = React.lazy(() => import("./localstoragelocations/AddGCSLocalStorageLocation"));
const ShowLocalStorageLocation = React.lazy(() => import("./localstoragelocations/ShowLocalStorageLocation"));
const ReplaceLocalStorageLocationKeyPair = React.lazy(() => import("./localstoragelocations/ReplaceLocalStorageLocationKeyPair"));
const SelectImpersonation = React.lazy(() => import("./impersonation/SelectImpersonation"));

const Layout = (props) => {
    // https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
    const routes = [
        { path: "/", exact: true, name: "Home" },
        { path: "/isLoggedIn", exact: true, name: "Is Logged In", component: IsLoggedIn },
        { path: "/dashboard", name: "Dashboard", activeItem: "Dashboard", component: Dashboard },
        { path: "/alerts", name: "Alerts", activeItem: "Alerts", component: Alerts },
        { path: "/groups/add", name: "AddGroup", activeItem: "Groups", component: AddGroup },
        { path: "/groups/:groupId", name: "EditGroup", activeItem: "Groups", component: EditGroup },
        { path: "/groups", name: "Groups", activeItem: "Groups", component: Groups },
        { path: "/accounts/add", name: "AddAccount", activeItem: "Accounts", component: AddAccount },
        { path: "/accounts/edit/:accountId", name: "EditAccount", activeItem: "Accounts", component: EditAccount },
        { path: "/accounts/:accountId", name: "ShowAccount", activeItem: "Accounts", component: ShowAccount },
        { path: "/accounts", name: "Accounts", activeItem: "Accounts", component: Accounts },
        { path: "/computers/edit/:id", name: "Edit Computer", activeItem: "Computers", component: EditComputer },
        { path: "/computers/setAppPassword/:proComputerId", name: "Edit Computer", activeItem: "Computers", component: SetAppPassword },
        { path: "/computers/:id", name: "Show Computer", activeItem: "Computers", component: ShowComputer },
        { path: "/computers", name: "Computers", activeItem: "Computers", component: Computers },
        { path: "/storageLocations/editSFTP/:storageLocationId", name: "Edit SFTP Storage Location", activeItem: "Storage Locations", component: EditSFTPStorageLocation },
        { path: "/storageLocations/editName/:storageLocationId", name: "Edit Storage Location Name", activeItem: "Storage Locations", component: EditStorageLocationName },
        {
            path: "/storageLocations/replaceKeyPair/:storageLocationId",
            name: "Replace Storage Location Key Pair",
            activeItem: "Storage Locations",
            component: ReplaceStorageLocationKeyPair
        },
        { path: "/storageLocations/add", name: "AddStorageLocation", activeItem: "Storage Locations", component: AddStorageLocation },
        { path: "/storageLocations/browse/:storageLocationId", name: "BrowseStorageLocation", activeItem: "Storage Locations", component: BrowseStorageLocation },
        { path: "/storageLocations/:storageLocationId", name: "ShowStorageLocation", activeItem: "Storage Locations", component: ShowStorageLocation },
        { path: "/storageLocations", name: "StorageLocations", activeItem: "Storage Locations", component: StorageLocations },
        { path: "/backupplans/add", name: "AddBackupPlan", activeItem: "Backup Plans", component: AddBackupPlan },
        { path: "/backupplans/:backupPlanId", name: "EditBackupPlan", activeItem: "Backup Plans", component: EditBackupPlan },
        { path: "/backupplans", name: "BackupPlans", activeItem: "Backup Plans", component: BackupPlans },
        { path: "/localbackupplans/add/:proComputerId", name: "AddLocalBackupPlan", activeItem: "Computers", component: AddLocalBackupPlan },
        { path: "/localbackupplans/:localBackupPlanId", name: "EditLocalBackupPlan", activeItem: "Computers", component: EditLocalBackupPlan },
        { path: "/localstoragelocations/add/:proComputerId", name: "AddLocalStorageLocation", activeItem: "Computers", component: AddLocalStorageLocation },
        {
            path: "/localstoragelocations/addOAuth/:storageLocationType/:proComputerId",
            name: "AddLocalStorageLocation",
            activeItem: "Computers",
            component: AddOAuthLocalStorageLocation
        },
        {
            path: "/localstoragelocations/addS3Compatible/:proComputerId",
            name: "AddLocalStorageLocation",
            activeItem: "Computers",
            component: AddS3CompatibleLocalStorageLocation
        },
        {
            path: "/localstoragelocations/addS3/:storageLocationType/:proComputerId",
            name: "AddLocalStorageLocation",
            activeItem: "Computers",
            component: AddS3LocalStorageLocation
        },
        {
            path: "/localstoragelocations/addB2/:proComputerId",
            name: "AddLocalStorageLocation",
            activeItem: "Computers",
            component: AddB2LocalStorageLocation
        },
        {
            path: "/localstoragelocations/addGCS/:proComputerId",
            name: "AddLocalStorageLocation",
            activeItem: "Computers",
            component: AddGCSLocalStorageLocation
        },
        {
            path: "/localstoragelocations/addSFTP/:proComputerId",
            name: "AddLocalStorageLocation",
            activeItem: "Computers",
            component: AddSFTPLocalStorageLocation
        },
        { path: "/localstorageLocations/editName/:localStorageLocationId", name: "Edit Local Storage Location Name", activeItem: "Computers", component: EditStorageLocationName },
        {
            path: "/localstorageLocations/replaceKeyPair/:localStorageLocationId",
            name: "Replace Local Storage Location Key Pair",
            activeItem: "Computers",
            component: ReplaceLocalStorageLocationKeyPair
        },
        { path: "/localStorageLocations/browse/:localStorageLocationId", name: "BrowseLocalStorageLocation", activeItem: "Computers", component: BrowseStorageLocation },
        { path: "/localstoragelocations/:localStorageLocationId", name: "ShowLocalStorageLocation", activeItem: "Computers", component: ShowLocalStorageLocation },
        { path: "/activities/activityLog/:activityId", name: "Activities", activeItem: "Activities", component: ActivityLog },
        { path: "/activities", name: "Activities", activeItem: "Activities", component: Activities },
        { path: "/subscription/enterPaymentMethod", name: "Enter Payment Method", activeItem: "Subscription", component: EnterPaymentMethod },
        { path: "/subscription/editName", name: "Edit Subscription Name", activeItem: "Subscription", component: EditSubscriptionName },
        { path: "/subscription", name: "Subscription", activeItem: "Subscription", component: Subscription },
        { path: "/impersonation/SelectImpersonation", name: "Admin", activeItem: "Subscription", component: SelectImpersonation }
    ];

    const [guidedSetup, setGuidedSetup] = useState({ visible: false, step: 0, expanded: true });

    const loggedInAccount = ReactSession.get("loggedInAccount");
    const loggedInProSubscription = ReactSession.get("loggedInProSubscription");
    if (!loggedInAccount || !loggedInProSubscription || !loggedInAccount.date_arq_pro_license_accepted) {
        return <IsLoggedIn />;
    }

    return (
        <Switch>
            {routes.map((route, idx) => {
                if (route.component) {
                    return (
                        <Route key={idx} path={route.path} exact={route.exact} name={route.name}>
                            <DesktopMain activeItem={route.activeItem} guidedSetup={guidedSetup} setGuidedSetup={setGuidedSetup} {...props}>
                                <route.component guidedSetup={guidedSetup} setGuidedSetup={setGuidedSetup} {...props} />
                            </DesktopMain>
                        </Route>
                    );
                }
                return null;
            })}
            <Redirect from="/" to="/dashboard" />
        </Switch>
    );
};

export default Layout;
