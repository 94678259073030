import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Flex, useToast, useColorModeValue } from "@chakra-ui/react";
import ArqProServerAPI from "./ArqProServerAPI";

const Logout = (props) => {
    const toast = useToast();

    let history = useHistory();

    useEffect(() => {
        async function fetchData() {
            // try/catch must be within this async function, not around the fetchData() call.

            try {
                await ArqProServerAPI.logOut();
                history.replace("/login");
            } catch (err) {
                const response = err.response;
                let message = err.message;
                if (response && response.data && response.data.error) {
                    message = response.data.error;
                }
                toast({ title: "Error", description: message, status: "error", duration: 5000, isClosable: true });
            }
        }
        fetchData();
    }, [history, toast]);

    return (
        <Flex minH={"100vh"} align={"center"} justify={"center"} bg={useColorModeValue("gray.50", "gray.800")}>
            Logging out...
        </Flex>
    );
};

export default Logout;
