import React, { useState, useEffect } from "react";
import ArqProServerAPI from "./ArqProServerAPI";
import { Flex, Select, Box, VStack, Stack, Button, Heading, useColorModeValue, useToast } from "@chakra-ui/react";
import { ReactSession } from "react-client-session";

const SelectProSubscription = (props) => {
    const { proSubscriptions, onOK, onCancel } = props;
    const [selectedProSubscriptionId, setSelectedProSubscriptionId] = useState(proSubscriptions[0].data.id);
    const [loggingIn, setLoggingIn] = useState(false);

    const bgColor = useColorModeValue("gray.50", "gray.800");
    const boxBGColor = useColorModeValue("white", "gray.700");
    const toast = useToast();

    // I tried to consolidate the duplicate code in the next 2 methods but kept running into hook errors.
    useEffect(() => {
        async function fetchData() {
            if (proSubscriptions.length === 1 && !loggingIn) {
                try {
                    setLoggingIn(true);
                    await ArqProServerAPI.setArqProSubscriptionId({
                        id: selectedProSubscriptionId
                    });
                    const selectedProSubscriptions = proSubscriptions.filter((sub) => sub.data.id === selectedProSubscriptionId);
                    ReactSession.set("loggedInProSubscription", selectedProSubscriptions[0].data);
                    onOK(selectedProSubscriptions[0].data.id);
                } catch (err) {
                    const response = err.response;
                    let message = err.message;
                    if (response && response.data && response.data.error) {
                        message = response.data.error;
                    }
                    if (response.status === 401) {
                        onCancel();
                        return;
                    }
                    toast({ title: "Error", description: message, status: "error", duration: 5000, isClosable: true });
                } finally {
                    setLoggingIn(false);
                }
            }
        }
        fetchData();
    }, [loggingIn, proSubscriptions, onCancel, onOK, selectedProSubscriptionId, toast]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoggingIn(true);
            await ArqProServerAPI.setArqProSubscriptionId({
                id: selectedProSubscriptionId
            });
            const selectedProSubscriptions = proSubscriptions.filter((sub) => sub.data.id === selectedProSubscriptionId);
            ReactSession.set("loggedInProSubscription", selectedProSubscriptions[0].data);
            onOK(selectedProSubscriptions[0].data.id);
        } catch (err) {
            const response = err.response;
            let message = err.message;
            if (response && response.data && response.data.error) {
                message = response.data.error;
            }
            if (response.status === 401) {
                onCancel();
                return;
            }
            toast({ title: "Error", description: message, status: "error", duration: 5000, isClosable: true });
        } finally {
            setLoggingIn(false);
        }

        try {
        } catch (err) {}
    };

    // Show a blank page if proSubscriptions.length is 1, because we're going to log in and redirect to dashboard anyway.
    return (
        <Flex minH={"100vh"} align={"center"} justify={"center"} bg={bgColor}>
            {proSubscriptions.length > 1 && (
                <VStack spacing={8} mx={"auto"} w={"lg"} py={12} px={6}>
                    <Stack align={"center"}>
                        <Heading fontSize={"2xl"}>Choose Arq Pro Subscription</Heading>
                    </Stack>
                    <Box w="100%" rounded={"lg"} bg={boxBGColor} boxShadow={"lg"} p={8}>
                        <form onSubmit={handleSubmit}>
                            <Stack spacing={4}>
                                <Select size="sm" onChange={(e) => setSelectedProSubscriptionId(parseInt(e.target.value))}>
                                    {proSubscriptions.map((proSubscription) => {
                                        const dateCreated = new Date(Date.parse(proSubscription.data.date_created));
                                        return (
                                            <option key={proSubscription.data.id} value={proSubscription.data.id}>
                                                {proSubscription.data.name} (created {dateCreated.toLocaleString()})
                                            </option>
                                        );
                                    })}
                                </Select>
                                <Button
                                    disabled={loggingIn}
                                    type="submit"
                                    bg={"blue.400"}
                                    color={"white"}
                                    _hover={{
                                        bg: "blue.500"
                                    }}
                                >
                                    Continue
                                </Button>
                            </Stack>
                        </form>
                    </Box>
                </VStack>
            )}
        </Flex>
    );
};

export default SelectProSubscription;
