import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import ArqProServerAPI from "./ArqProServerAPI";
import { Flex, Box, VStack, Stack, Button, Heading, useColorModeValue, useToast } from "@chakra-ui/react";
import { ReactSession } from "react-client-session";

const AcceptLicense = (props) => {
    const [downloaded, setDownloaded] = useState(false);

    const bgColor = useColorModeValue("gray.50", "gray.800");
    const boxBGColor = useColorModeValue("white", "gray.700");

    const history = useHistory();
    const toast = useToast();

    const handleAccept = async () => {
        try {
            // This returns an updated Account object:
            const response = await ArqProServerAPI.acceptLicense();
            ReactSession.set("loggedInAccount", response.data.data);
            history.replace("/dashboard");
        } catch (err) {
            const response = err.response;
            if (response?.status === 401) {
                // Not logged in.
                history.replace("/login");
                return;
            }
            let message = err.message;
            if (response && response.data && response.data.error) {
                message = response.data.error;
            }
            toast({ title: "Error", description: message, status: "error", duration: 5000, isClosable: true });
        }
    };

    return (
        <Flex minH={"100vh"} align={"center"} justify={"center"} bg={bgColor}>
            <VStack spacing={8} mx={"auto"} w={"xl"} py={12} px={6}>
                <Stack align={"center"}>
                    <Heading fontSize={"4xl"}>Arq Pro License Agreement</Heading>
                </Stack>
                <Box w="100%" rounded={"lg"} bg={boxBGColor} boxShadow={"lg"} p={8}>
                    <Stack spacing={4}>
                        <VStack spacing={6}>
                            <Flex>Please download and review the Arq Pro license agreement:</Flex>
                            {!downloaded ? (
                                <>
                                    <Flex>
                                        <a href="https://www.arqbackup.com/ArqProLicense.rtf">
                                            <Button
                                                bg={"blue.400"}
                                                color={"white"}
                                                _hover={{
                                                    bg: "blue.500"
                                                }}
                                                onClick={() => setDownloaded(true)}
                                            >
                                                Download Arq Pro License Agreement
                                            </Button>
                                        </a>
                                    </Flex>
                                </>
                            ) : (
                                <>
                                    <Flex>
                                        <a href="https://www.arqbackup.com/ArqProLicense.rtf">
                                            <Button>Download Arq Pro License Agreement</Button>
                                        </a>
                                    </Flex>
                                    <VStack>
                                        <Flex mt={6}>Click "I accept" to accept the license:</Flex>
                                        <Flex>
                                            <Button
                                                onClick={handleAccept}
                                                bg={"blue.400"}
                                                color={"white"}
                                                _hover={{
                                                    bg: "blue.500"
                                                }}
                                                disabled={!downloaded}
                                            >
                                                I Accept
                                            </Button>
                                        </Flex>
                                    </VStack>
                                </>
                            )}
                        </VStack>
                    </Stack>
                </Box>
            </VStack>
        </Flex>
    );
};

export default AcceptLicense;
